import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Button, Media
} from 'reactstrap';
const axios = require('../config/axiosInstance3');
const axios2 = require('axios');
const axios4 = require('../config/axiosInstance4');
const axios5 = require('../config/axiosInstance5');
const { WebClient } = require('@slack/web-api');
const web = new WebClient(process.env.REACT_APP_SLACK_TESTBOT_TOKEN);


// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_aubx4QrCXgh7gmaqmyjx5jHQ00u6EtZOmX');

const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');

export default function TeamsPageBilling(props) {


  const [initType, setInitType] = useState();
  const [initRef, setInitRef] = useState();
 
  const [country, setCountry] = useState("USA");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(true);




  // // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

   
  
    (async () => {
      try {

        // comment out these two
        // var ipRes = await axios.get('https://ipapi.co/json/')
        // ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")


        let search = window.location.search;
        let params = new URLSearchParams(search);
        let ref = params.get('ref');
        let firstType = params.get('type');

        ref === null ? setInitRef('null') : setInitRef(ref)
        firstType === null ? setInitType('null') : setInitType(firstType)


   

      
        // new apr 2024 - create an api called prebilling auth and then from there put a slack web api call to post messages
        const configInitPrev = {
          headers: {
            'Content-Type': 'application/json',
            'password': 'jnasUw313411994r394LLP01092388ah'
          }
        };



        const bodyInitPrev = JSON.stringify({ 
          ref: ref,
          type: firstType
        });

    

     
        const resInitPrev = await axios5.post(`/api/newRandBillingAuth`, bodyInitPrev, configInitPrev);




        

        
      } catch (error) {
        console.log(error);
      }
      
    })();
      
    
  }, []);

  // early stage monthly

  const handleClickPrice = async (zType, zDuration, leLevel) => {
    setLoading(true)


    var teamId;
    var tenantId;
    var channelId;
    var numUsers;

    if(initRef === 'null' || initRef === null || initType === 'null' || initType === null){
      teamId = 'null';
      tenantId = 'null';
      channelId = 'null';
      numUsers = 20;
    }
    else {
      const configInit = {
        headers: {
          'Content-Type': 'application/json',
          'password': 'jnasUw313411994r394LLP01092388ah'
        }
      };
  
  
      const bodyInit = JSON.stringify({ 
        ref: initRef,
        type: initType
      });
   
      const resInit = await axios5.post(`/api/billingauth`, bodyInit, configInit);
  
      teamId = resInit.data.teamId;
      tenantId = resInit.data.tenantId;
      channelId = resInit.data.channelId;
      numUsers = resInit.data.numUsers === 'null' ? 20 
        : resInit.data.numUsers < 10 ? 10 : resInit.data.numUsers
    }


   

    

    var priceId;
    var numItems;

    if(zType === 'Org' || zType === 'OrgChat'){
      numItems = 1;
      if(leLevel === "Starter"){
        priceId = zDuration === "Monthly" ? 'price_1Q7GGAJBEzpLhH7BZhO7QoMb'
          : 'price_1Q7GFgJBEzpLhH7B26c57kyV'
      }
      else if(leLevel === "Basic"){
        priceId = zDuration === "Monthly" ? 'price_1LfhSCJBEzpLhH7BHamNIxKp'
          : 'price_1LfhSbJBEzpLhH7BYIOOSu0x'
      }
      else {
        priceId = zDuration === "Monthly" ? 'price_1L82MdJBEzpLhH7BUXYCfrMz'
          : 'price_1L82MdJBEzpLhH7B0S93cSSR'
      }
  }
  else if(zType === 'Chat'){
      priceId = zDuration === "Monthly" ? 'price_1PGOFyJBEzpLhH7BnLXgTfU4'
      : 'price_1PGOHCJBEzpLhH7Bozm2s2Lk'

      numItems = 1
  }

    
    
    

   
    // else if(zType === 'Team'){
    //     priceId = zDuration === "Monthly" ? 'price_1L82MdJBEzpLhH7B59yZZzij'
    //     : 'price_1L82MdJBEzpLhH7BfWBya1IE'

    //     numItems = numUsers;
    // }

    // When the customer clicks on the button, redirect them to Checkout.
    // const stripe = await stripePromise;



    const configT = {
      headers: {
          'Content-Type': 'application/json',
      }
    };

    // uncomment

    const stripe = await stripePromise;

    var clientRefStuff
    if (window.Rewardful && window.Rewardful.referral) {
      clientRefStuff = window.Rewardful.referral;
    }
    else {
      clientRefStuff = "None"
    }

    // type - Team, Chat, Org, OrgChat
    const body = JSON.stringify({ 
        priceId,
        type: zType,
        duration: zDuration,
        numItems,
        teamId,
        tenantId, 
        channelId,
        clientRefStuff
    });



    const res = await axios4.post('/api/checkoutSessionNew/teamssub', body, configT);
    var sessionId = res.data;

    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId
    });

    
  };

  const [planDuration, setPlanDuration] = useState('Yearly'); // Default to monthly

  const handleDurationToggle = () => {
      setPlanDuration(planDuration === 'Monthly' ? 'Yearly' : 'Monthly');
  };

 

  var currency = '$'

  var blockTeams = (leDuration) => (<React.Fragment>
    <div className = "row">

      {/* New Starter Org Plan */}
      <div className="col-lg-3">
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
            <h5 className="card-title text-muted text-uppercase text-center">Starter Org plan - {leDuration}</h5>
            <h6 className="card-price text-center">{currency}{leDuration === "Monthly" ? "34/month" : "288/year"}<span className="period"></span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Up to 40 users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>        
            </ul>
            <Button className="btn btn-block btn-primary text-uppercase" onClick={() => handleClickPrice('Org', leDuration, "Starter")} disabled={loading}>{loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Select plan</span>}</Button>
          </div>
        </div>
      </div>

      {/* Existing Basic Org Plan */}
      <div className="col-lg-3">
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
            <h5 className="card-title text-muted text-uppercase text-center">Basic Org plan - {leDuration}</h5>
            <h6 className="card-price text-center">{currency}{leDuration === "Monthly" ? "59/month" : "480/year"}<span className="period"></span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Up to 100 users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>        
            </ul>
            <Button className="btn btn-block btn-primary text-uppercase" onClick={() => handleClickPrice('Org', leDuration, "Basic")} disabled={loading}>{loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Select plan</span>}</Button>
          </div>
        </div>
      </div>

      {/* Existing Growth Org Plan */}
      <div className="col-lg-3">
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
          <h5 className="card-title text-muted text-uppercase text-center">Growth Org plan - {leDuration}</h5>
            <h6 className="card-price text-center">{currency}{leDuration === "Monthly" ? "99/month" : "948/year"}<span className="period"></span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Up to 250 users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>     
            </ul>
            <Button className="btn btn-block btn-primary text-uppercase" onClick={() => handleClickPrice('Org', leDuration, "Growth")} disabled={loading}>{loading && (
                <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
                />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span>Select plan</span>}</Button>
          </div>
        </div>
      </div>

      {/* Existing Enterprise Plan */}
      <div className="col-lg-3">
        <div className="card mb-5 mb-lg-0">
          <div className="card-body">
          <h5 className="card-title text-muted text-uppercase text-center">Enterprise plan - {leDuration}</h5>
            <h6 className="card-price text-center">Contact us<span className="period"></span></h6>
            <hr />
            <ul className="fa-ul">
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Above 250 users</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
              <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>  
            </ul>
            <span>Contact us on cheese@ricotta.team to know more about Enterprise pricing</span>
          </div>
        </div>
      </div>

    </div>
  </React.Fragment>) 


// chat plans

var blockTeamsChat = (leDuration) => (<React.Fragment>
  <div className = "row">

  {/* <br></br>Use coupon code RICOTTANEWYEAR10 to get 10% off. Valid on all plans until Jan 31st 2024. */}

  {/* New Starter Org Plan */}
  <div className="col-lg-3">
    <div className="card mb-5 mb-lg-0">
      <div className="card-body">
        <h5 className="card-title text-muted text-uppercase text-center">Starter Org plan - {leDuration}</h5>
        <h6 className="card-price text-center">{currency}{leDuration === "Monthly" ? "34/month" : "288/year"}<span className="period"></span></h6>
        <hr />
        <ul className="fa-ul">
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Up to 40 users</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>        
        </ul>
        <Button className="btn btn-block btn-primary text-uppercase" onClick={() => handleClickPrice('OrgChat', leDuration, "Starter")} disabled={loading}>{loading && (
            <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px" }}
            />
        )}
        {loading && <span>Loading...</span>}
        {!loading && <span>Select plan</span>}</Button>
      </div>
    </div>
  </div>

  <div className="col-lg-3">
    <div className="card mb-5 mb-lg-0">
      <div className="card-body">
      <h5 className="card-title text-muted text-uppercase text-center">Basic Org plan - {leDuration}</h5>
        <h6 className="card-price text-center">{currency}{leDuration === "Monthly" ? "59/month" : "480/year"}<span className="period"></span></h6>
        <hr />
        <ul className="fa-ul">
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Up to 100 users</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>        
        </ul>
        <Button className="btn btn-block btn-primary text-uppercase" onClick={() => handleClickPrice('OrgChat', leDuration, "Basic")} disabled={loading}>{loading && (
            <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px" }}
            />
        )}
        {loading && <span>Loading...</span>}
        {!loading && <span>Select plan</span>}</Button>
      </div>
    </div>
  </div>

  <div className="col-lg-3">
    <div className="card mb-5 mb-lg-0">
      <div className="card-body">
      <h5 className="card-title text-muted text-uppercase text-center">Growth Org plan - {leDuration}</h5>
        <h6 className="card-price text-center">{currency}{leDuration === "Monthly" ? "99/month" : "948/year"}<span className="period"></span></h6>
        <hr />
        <ul className="fa-ul">
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Up to 250 users</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>     
        </ul>
        <Button className="btn btn-block btn-primary text-uppercase" onClick={() => handleClickPrice('OrgChat', leDuration, "Growth")} disabled={loading}>{loading && (
            <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px" }}
            />
        )}
        {loading && <span>Loading...</span>}
        {!loading && <span>Select plan</span>}</Button>
      </div>
    </div>
  </div>

  <div className="col-lg-3">
    <div className="card mb-5 mb-lg-0">
      <div className="card-body">
      <h5 className="card-title text-muted text-uppercase text-center">Enterprise plan - {leDuration}</h5>
        <h6 className="card-price text-center">Contact us<span className="period"></span></h6>
        <hr />
        <ul className="fa-ul">
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Above 250 users</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>Supports unlimited games in your entire organization</li>                      
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Trivia</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Water Cooler Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player Games</li>    
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Icebreaker Games</li>
          <li><span className="fa-li"><i className="fa fa-check"></i></span>All Social Games</li>  
        </ul>
        <span>Contact us on cheese@ricotta.team for Enterprise pricing</span>
      </div>
    </div>
  </div>

  </div>
</React.Fragment>)




  return (
    <React.Fragment>
      <div className ="container mt-5">
        <h3 className="billing-management-header">Ricotta Games & Trivia on Microsoft Teams - Billing Plans</h3>
        <p className="ml-4">For <b>Enterprise plans</b> or further queries, please reach out to us on cheese@ricotta.team</p>
        <div className="d-flex justify-content-center mb-4">
                    <div className="btn-group" role="group" aria-label="Plan duration">
                        <button
                            type="button"
                            className={`btn ${planDuration === 'Monthly' ? 'btn-primary-tpb' : 'btn-outline-primary-tpb'}`}
                            onClick={() => setPlanDuration('Monthly')}
                        >
                            Monthly
                        </button>
                        <button
                            type="button"
                            className={`btn ${planDuration === 'Yearly' ? 'btn-primary-tpb' : 'btn-outline-primary-tpb'}`}
                            onClick={() => setPlanDuration('Yearly')}
                        >
                            Yearly
                        </button>
                    </div>
                </div>

            {/* <div className="d-flex justify-content-center mb-4">
                    <button className="btn-switch text-uppercase" onClick={handleDurationToggle}>
                        Switch to {planDuration === 'Monthly' ? 'Yearly' : 'Monthly'} Plans
                    </button>
            </div> */}
        {/* {loaded === false ? (<p className ="mt-2">Loading...please wait!</p>) : (<p className ="mt-2">Ricotta Trivia Billing Dashboard</p>)} */}

        {/* {isAdmin === true ? isBillingActive === true ? 
          (<p className ="lead mt-3">Hello {name}, to update or cancel your plan, please contact us at <a href = "mailto: cheese@ricotta.team">cheese@ricotta.team</a></p>) 
              : (<p className ="lead mt-3"> Hello {name}, please choose your plan and proceed.</p>) 
                : isAdmin === false ? (<p className ="lead mt-3">Hello, please choose your plan and proceed</p>)
                : errorText} */}

      </div> 

    <section className="pricing py-2">
            <div className="container">
              <div className="row mt-1">

                {initType === 'Org' ? blockTeams(planDuration) : initType === 'Chat' ? blockTeamsChat(planDuration) : blockTeams(planDuration)}

              {/* {isDummy === true || isDummy === false ? isBillingActive === true ? (<div className="container">
                  <Media>
                    <img src="/assets/mailbox.svg" className = "img-fluid billing-management-image" alt="Ricotta on Slack" />
                  </Media>
                  </div>) : 
                  block : console.log('nothing here')
              } */}
                
              </div>
            </div>

            
          </section>
    </React.Fragment>
  );
}